// Importing the Head component from Next.js for managing the head of the document
import Head from 'next/head';
import { UserContext } from '../../contexts/UserContext';
import { useContext } from 'react';

/**
 * SEO component for setting the HTML document head elements like title and meta tags.
 * 
 * This component is used to define the SEO (Search Engine Optimization) properties
 * of a page, such as the title, description, and keywords. These properties help
 * improve the visibility and ranking of the page in search engine results.
 *
 * @param {Object} props - The props passed to the component.
 * @param {string} props.title - The title of the page.
 * @param {string} props.description - The description of the page.p
 * @param {string} props.keyword - The keywords associated with the page.
 * @returns {React.ReactElement} - A Next.js Head component with SEO tags.
 */
// default data changes in to empty - 29/06/2024
export default function SEO({ title="", description="", keyword="" }) {
    // Console log for debugging purposes: logs the SEO data being used
   

    // Return the Head component with SEO related elements
    const { domainData } = useContext(UserContext);
    var siteName = domainData.seo_domain_name || window.location.hostname;   
    return (
        <Head>
            {/* Setting the title of the page */}
            <title>{title +" | "+ siteName}</title> 

            {/* Meta tag for the description of the page */}
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
            <meta name="title" content={title} />

            {/* Open Graph meta tags for better link previews on social platforms */}
            {/* <meta property="og:title" content={title} /> */}
            {/* <meta property="og:description" content={description} /> */}
            
            {/* Meta tag for keywords associated with the page */}
            {/* Note: The effectiveness of meta keywords for SEO is debated and may not be significant */}
            {/* <meta property="og:keyword" content={keyword} /> */}
        </Head>
    );
}
