import { useContext } from 'react';
import { useAxios } from '../../utils/axios';
import axios from 'axios';
import { ThemeContext } from '../../contexts/ThemeContext';

const useTracking = (pageType) => {
  const axiosInstance = useAxios();
  const theme = useContext(ThemeContext);

  // Helper function to generate a random color
  const getRandomColor = () => {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += '0123456789ABCDEF'[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Helper function to generate a unique identifier
  const generateScode = () => {
    const currentdate = new Date();
    const datetime = `${currentdate.getFullYear()}${(currentdate.getMonth() + 1).toString().padStart(2, '0')}${currentdate.getDate().toString().padStart(2, '0')}${currentdate.getHours().toString().padStart(2, '0')}${currentdate.getMinutes().toString().padStart(2, '0')}${currentdate.getSeconds().toString().padStart(2, '0')}`;
    return datetime + getRandomColor();
  };

  const trackPage = async () => {
    // Check if the site status is 1 before making the API call
    if (theme.siteStatus === 1) {
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipDetails = await axios.get(`https://ipapi.co/${ipResponse.data.ip}/json/`);

        const payload = {
          ipDetails: JSON.stringify(ipDetails.data),
          reference: window.location.hostname,
          track: 'track',
          page: window.location.href,
          method: 'open',
          page_type: pageType,
          UID: '',  // Placeholder for UID generation
          scode: generateScode(),
        };

        await axiosInstance.post('user/tracking', payload);
      } catch (error) {
        console.error('Error sending tracking data:', error);
      }
    } else {
      console.error('Tracking not enabled due to site status.');
    }
  };

  return trackPage;
};

export default useTracking;
